import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useCharacters } from '@lib/core/characters/hooks';
import { isCharacterByIdentifiers, isCharacterByServiceProductCategory } from '@lib/core/characters/utils/filters';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import {
  ACTION_PERFORMED_URL_PARAM,
  B2C_GET_PRODUCTS_REQUEST_LIMIT,
  DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM,
  ENABLE_PROMOTION_TOGGLE_URL_PARAM,
  ENABLE_WISHLIST_FILTER_URL_PARAM,
  FORMAT_QUERY,
  GPRL_CHARACTER_QUERY,
  GPRL_PROMOTIONS_QUERY,
  IS_FROM_PRODUCT_DETAILS_PAGE,
  MIXPANEL_EVENT_SLUG_URL_PARAM,
  ModalTypes,
  ORIGINS_QUERY,
  PREFERENCES_QUERY,
  PRODUCTS_MAX_PRICE_QUERY,
  PRODUCTS_MIN_PRICE_QUERY,
  PRODUCT_CHARACTERISTICS_QUERY,
  PRODUCT_NAME_QUERY,
  PRODUCT_PREFERENCES_URL_PARAM,
  REDIRECT_URL_PARAM,
  SEARCH_BY_NAME_FIELDS_QUERY,
  STYLE_QUERY,
} from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useProductList, useUser } from '@lib/core/users/hooks';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_ACTION_PERFORMED_CONTEXT, MP_EVENTS } from '@lib/tools/dat/mixpanel/consts';
import { useCatalogFilter } from '@lib/tools/filterManager/hooks';
import {
  TProductCatalogOrdering,
  actionApplyCatalogOrdering,
  actionResetCatalogFilters,
} from '@lib/tools/filterManager/slices/productFilter';
import { updateShowOnlyFilterValue } from '@lib/tools/filterManager/slices/showOnlyFilterSlice';
import {
  updateCharactersToggleActive,
  updatePromotionToggleActive,
} from '@lib/tools/filterManager/slices/toggleFilterSlice';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { useProductCatalog } from '@lib/tools/productCatalog/hooks/useProductCatalog';
import { actionFetchProductsListsExtended, resetProductCatalogState } from '@lib/tools/productCatalog/slices';
import { usePromotionProducts } from '@lib/tools/promotionProducts/hooks/usePromotionProducts';
import RouteUtils from '@lib/tools/routes';
import { removeUrlParams, setUrlParams } from '@lib/tools/shared/helpers';
import {
  CHARACTERS_URL_PARAM,
  FIDELITY_ID_URL_PARAM,
  FILTER_TYPE_CHARACTERISTICS,
  FILTER_TYPE_CHARACTER_TOGGLE,
  FILTER_TYPE_FORMAT,
  FILTER_TYPE_ITEM_NAME,
  FILTER_TYPE_ORDERING,
  FILTER_TYPE_ORIGIN,
  FILTER_TYPE_PRICE_RANGE,
  FILTER_TYPE_PRICE_RANGE_MAX,
  FILTER_TYPE_PRICE_RANGE_MIN,
  FILTER_TYPE_PROMOTION_TOGGLE,
  FILTER_TYPE_SHOW_FAVORITES,
  FILTER_TYPE_STYLE,
  FILTER_TYPE_WISHLIST,
  OS_PRODUCT_NAME_QUERY,
  PRODUCT_CATEGORY_QUERY,
  PRODUCT_IDENTIFIER_QUERY,
  PROMOTION_LABEL_SLUG,
} from '@lib/tools/shared/helpers/consts';
import { IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';
import { useAddons, useTypedSelector } from '@lib/tools/views/hooks';

import ProductCatalogPage from '@components/web/src/pages/kiosk/ProductCatalogPage/ProductCatalogPage';
import EventsModal from '@components/web/src/templates/Modals/Events/EventsModal/EventsModal';

const ProductCatalogContainer = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { retailerSlug } = useRetailer();
  const { locale, productCategory } = useApp();
  const { retailerStoreType, retailerPhysicalAddress } = useRetailerLocation();
  const {
    isEnableLocationMapAddon,
    isEnableVusionAddon,
    isShowProductLocationAddon,
    isEnableKioskFidelityPromotionAddon,
    isPriceRangeAddon,
  } = useAddons();
  const { userCharacters } = useUser();
  const { wishlistProductInstanceIds, isWishlistProductListLoading, handleUpdateWishlistProductList } =
    useProductList();
  const { characters } = useCharacters();

  const disableUserCharacterToggleUrlParam = !!searchParams.get(DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM);
  const charactersStringUrlParam = searchParams.get(CHARACTERS_URL_PARAM) || '';
  const preferencesFromUrl = searchParams.get(PRODUCT_PREFERENCES_URL_PARAM);
  const isFromProductDetailsPage = searchParams.get(IS_FROM_PRODUCT_DETAILS_PAGE);
  const isEnablePromotionToggle = !!searchParams.get(ENABLE_PROMOTION_TOGGLE_URL_PARAM);
  const isEnableWishlistFilter = !!searchParams.get(ENABLE_WISHLIST_FILTER_URL_PARAM);

  const { isPromotionProductsAvailable } = usePromotionProducts();
  const { isCharacterToggleActive = false } = useTypedSelector(state => state.productFilters.toggle);

  const { isProductsRequestLoading, lastDataLoadedTime, products, itemsCount } = useProductCatalog();

  const currentUserCharacterId =
    userCharacters.find(character => isCharacterByServiceProductCategory(character))?.identifier || '';

  const {
    [FILTER_TYPE_CHARACTERISTICS]: selectedCharacteristicsQuery,
    [FILTER_TYPE_FORMAT]: selectedFormatsQuery,
    [FILTER_TYPE_ORIGIN]: selectedOriginsQuery,
    [FILTER_TYPE_STYLE]: selectedStylesQuery,
    [FILTER_TYPE_PRICE_RANGE_MAX]: priceRangeMaxQuery,
    [FILTER_TYPE_ITEM_NAME]: searchTextValue,
    [FILTER_TYPE_PRICE_RANGE_MIN]: priceRangeMinQuery,
    [FILTER_TYPE_CHARACTER_TOGGLE]: isUseMyCharacterToggleEnabled,
    [FILTER_TYPE_SHOW_FAVORITES]: selectedWishlistProductsQuery,
    [FILTER_TYPE_ORDERING]: activeOrdering,
    [FILTER_TYPE_PROMOTION_TOGGLE]: useEnabledPromotionToggle,
    lastPriceRangeChangeWasManual,
  } = useCatalogFilter();
  const { productCatalog, commonMessages } = localeCommon;

  const { fidelityCardId, isFidelityCardScanned } = useFidelityCard();

  const [isPromotionModalOpen, setIsPromotionModalOpen] = useState(
    !!fidelityCardId && isEnableKioskFidelityPromotionAddon && !!retailerPhysicalAddress,
  );
  const promotionQrCodeURL = RouteUtils.generateRedirectURL({
    [ACTION_PERFORMED_URL_PARAM]: MP_ACTION_PERFORMED_CONTEXT.SCAN,
    [FIDELITY_ID_URL_PARAM]: `${retailerSlug}_${fidelityCardId}`,
    [MIXPANEL_EVENT_SLUG_URL_PARAM]: MP_EVENTS.SPECIAL_PROMO_DISPLAYED.SLUG,
    [REDIRECT_URL_PARAM]: retailerPhysicalAddress,
  });

  const isSituationalSameAsUserCharacter = charactersStringUrlParam === currentUserCharacterId;

  useEffect(() => {
    MixpanelTracker.events.productCatalogView(productCategory, isCharacterToggleActive);

    if (!isFromProductDetailsPage) {
      dispatch(actionResetCatalogFilters());
      dispatch(resetProductCatalogState());
    }

    if (
      ((charactersStringUrlParam && !isSituationalSameAsUserCharacter) || disableUserCharacterToggleUrlParam) &&
      !isFromProductDetailsPage
    ) {
      dispatch(updateCharactersToggleActive({ value: false }));
    }

    if (isFromProductDetailsPage) {
      removeUrlParams({
        keys: [IS_FROM_PRODUCT_DETAILS_PAGE],
      });
    }

    if (isEnablePromotionToggle) {
      dispatch(updatePromotionToggleActive({ value: true }));
    }

    if (isEnableWishlistFilter) {
      dispatch(updateShowOnlyFilterValue(FILTER_TYPE_WISHLIST));

      removeUrlParams({
        keys: [ENABLE_WISHLIST_FILTER_URL_PARAM],
      });
    }
  }, []);
  let characterQuery = '';

  if (isUseMyCharacterToggleEnabled) {
    characterQuery = currentUserCharacterId;
  } else if (!isSituationalSameAsUserCharacter) {
    characterQuery = charactersStringUrlParam;
  }

  const handleProductsRequest = ({ isPagination = false }: IHandleProductsRequestParams) => {
    const paginationParams = {
      limit: B2C_GET_PRODUCTS_REQUEST_LIMIT,
      offset: products.length,
    };

    const filterParams = { [PRODUCT_CATEGORY_QUERY]: productCategory };
    if (lastPriceRangeChangeWasManual) {
      filterParams[PRODUCTS_MAX_PRICE_QUERY] = priceRangeMaxQuery;
      filterParams[PRODUCTS_MIN_PRICE_QUERY] = priceRangeMinQuery;
    }

    if (searchTextValue) {
      filterParams[PRODUCT_NAME_QUERY] = `*${searchTextValue}*`;
      filterParams[SEARCH_BY_NAME_FIELDS_QUERY] = OS_PRODUCT_NAME_QUERY;
    }

    if (characterQuery) {
      filterParams[GPRL_CHARACTER_QUERY] = characterQuery;
    }

    if (useEnabledPromotionToggle) {
      filterParams[GPRL_PROMOTIONS_QUERY] = PROMOTION_LABEL_SLUG;
    }

    if (preferencesFromUrl) {
      filterParams[PREFERENCES_QUERY] = filterParams[PREFERENCES_QUERY]
        ? `${filterParams[PREFERENCES_QUERY]},${preferencesFromUrl}`
        : preferencesFromUrl;
    }

    if (selectedOriginsQuery) {
      filterParams[ORIGINS_QUERY] = selectedOriginsQuery;
    }

    if (selectedFormatsQuery) {
      filterParams[FORMAT_QUERY] = selectedFormatsQuery;
    }

    if (selectedStylesQuery) {
      filterParams[STYLE_QUERY] = selectedStylesQuery;
    }

    if (selectedCharacteristicsQuery) {
      filterParams[PRODUCT_CHARACTERISTICS_QUERY] = selectedCharacteristicsQuery;
    }

    if (selectedWishlistProductsQuery) {
      filterParams[PRODUCT_IDENTIFIER_QUERY] = selectedWishlistProductsQuery;
    }

    if (activeOrdering) {
      filterParams[FILTER_TYPE_ORDERING] = activeOrdering;
    }

    return dispatch(
      actionFetchProductsListsExtended({
        isPagination,
        params: {
          ...paginationParams,
          ...filterParams,
        },
      }),
    );
  };

  useEffect(() => {
    handleProductsRequest({});
  }, []);

  const handleCancelCharacter = characterID => {
    if (currentUserCharacterId.includes(characterID)) {
      dispatch(updateCharactersToggleActive({ value: false }));
    }
    const newCharactersArray = characterQuery
      .split('-')
      .filter(currentCharacterID => currentCharacterID !== characterID)
      .join('-');
    setUrlParams([{ key: CHARACTERS_URL_PARAM, value: newCharactersArray }]);
  };

  const translatedUserCharactersData = characters.filter(character =>
    isCharacterByIdentifiers(character, characterQuery.split('-')),
  );

  // ToDo avoid unnecessary data parsing
  const charactersFilterTagsData = useMemo(
    () =>
      translatedUserCharactersData.map(character => ({
        filterType: FILTER_TYPE_CHARACTER_TOGGLE,
        isActive: isUseMyCharacterToggleEnabled,
        name: character?.name,
        value: character?.identifier,
      })),
    [translatedUserCharactersData, isUseMyCharacterToggleEnabled],
  );

  const filterProps = {
    additionalCharacters: charactersFilterTagsData,
    additionalFiltersEnabled: {
      [FILTER_TYPE_CHARACTER_TOGGLE]: !!currentUserCharacterId,
      [FILTER_TYPE_PROMOTION_TOGGLE]: isPromotionProductsAvailable && isFidelityCardScanned && !!currentUserCharacterId,
      [FILTER_TYPE_SHOW_FAVORITES]: true,
    },
    disabledFilters: {
      [FILTER_TYPE_PRICE_RANGE]: isPriceRangeAddon,
    },
    handleCancelCharacter,
    isSearchEnabled: false,
  };

  const handleChangeOrdering = (newValue: TProductCatalogOrdering) => dispatch(actionApplyCatalogOrdering(newValue));

  return (
    <>
      <ProductCatalogPage
        shouldHideComment
        shouldHideFeedback
        activeOrdering={activeOrdering}
        filterProps={filterProps}
        handleChangeOrdering={handleChangeOrdering}
        handleProductsRequest={handleProductsRequest}
        handleUpdateWishlistProductList={handleUpdateWishlistProductList}
        isChangeOrderingEnabled={!!priceRangeMaxQuery}
        isEnableLocationMapAddon={isEnableLocationMapAddon}
        isEnableVusionAddon={isEnableVusionAddon}
        isProductsRequestLoading={isProductsRequestLoading || !lastDataLoadedTime}
        isShowProductLocationAddon={isShowProductLocationAddon}
        isWishlistProductListLoading={isWishlistProductListLoading}
        itemsCount={itemsCount}
        locale={locale}
        productCategory={productCategory}
        products={products}
        shouldHideWishlist={!isFidelityCardScanned}
        storeType={retailerStoreType}
        wishlistProductInstanceIds={wishlistProductInstanceIds}
      />
      {isPromotionModalOpen && (
        <EventsModal
          withButton
          buttonText={commonMessages.close}
          description={productCatalog.modalDescription}
          hideModal={() => setIsPromotionModalOpen(false)}
          icon="Bottles"
          isOpen={isPromotionModalOpen}
          modalType={ModalTypes.PromoQrModal}
          qrCodeUrl={promotionQrCodeURL}
          title={productCatalog.modalTitle}
        />
      )}
    </>
  );
};

export default ProductCatalogContainer;
