import { TProductCategory } from '@lib/core/products/types';
import { ThirdPartyConnectModalScenario } from '@lib/core/service/types/interface';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import BeerPlacesIcon from '@components/web/src/assets/png/beer/place_saved_beer_spark.png';
import BeerWishlistIcon from '@components/web/src/assets/png/beer/product_saved_beer_spark.png';
import BeerRate4Icon from '@components/web/src/assets/png/beer/rated_4_beer_spark.png';
import BeerRate5Icon from '@components/web/src/assets/png/beer/rated_5_beer_spark.png';
import CoffeePlacesIcon from '@components/web/src/assets/png/coffee/place_saved_coffee_spark.png';
import CoffeeWishlistIcon from '@components/web/src/assets/png/coffee/product_saved_coffee_spark.png';
import CoffeeRate4Icon from '@components/web/src/assets/png/coffee/rated_4_coffee_spark.png';
import CoffeeRate5Icon from '@components/web/src/assets/png/coffee/rated_5_coffee_spark.png';
import WinePlacesIcon from '@components/web/src/assets/png/wine/place_saved_wine_spark.png';
import WineWishlistIcon from '@components/web/src/assets/png/wine/product_saved_wine_spark.png';
import WineRate4Icon from '@components/web/src/assets/png/wine/rated_4_wine_spark.png';
import WineRate5Icon from '@components/web/src/assets/png/wine/rated_5_wine_spark.png';
import Button from '@components/web/src/atoms/Buttons/Button';
import CloseButton from '@components/web/src/components/Button/CloseButton/CloseButton';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';
import * as S from '@components/web/src/templates/Modals/ThirdPartyConnectModal/styles';

interface IThirdPartyConnectModalProps {
  retailerLogo: string;
  producerName: string;
  isOpen: boolean;
  productCategory: TProductCategory;
  productName: string;
  scenario:
    | ThirdPartyConnectModalScenario.LOCATION
    | ThirdPartyConnectModalScenario.RATE5
    | ThirdPartyConnectModalScenario.RATE4
    | ThirdPartyConnectModalScenario.WISHLIST;
  hideModal: () => void;
  handleBtnClick: (acceptance: boolean) => void;
}

const Icons = {
  beer: {
    [ThirdPartyConnectModalScenario.LOCATION]: BeerPlacesIcon,
    [ThirdPartyConnectModalScenario.RATE4]: BeerRate4Icon,
    [ThirdPartyConnectModalScenario.RATE5]: BeerRate5Icon,
    [ThirdPartyConnectModalScenario.WISHLIST]: BeerWishlistIcon,
  },
  coffee: {
    [ThirdPartyConnectModalScenario.LOCATION]: CoffeePlacesIcon,
    [ThirdPartyConnectModalScenario.RATE4]: CoffeeRate4Icon,
    [ThirdPartyConnectModalScenario.RATE5]: CoffeeRate5Icon,
    [ThirdPartyConnectModalScenario.WISHLIST]: CoffeeWishlistIcon,
  },
  wine: {
    [ThirdPartyConnectModalScenario.LOCATION]: WinePlacesIcon,
    [ThirdPartyConnectModalScenario.RATE4]: WineRate4Icon,
    [ThirdPartyConnectModalScenario.RATE5]: WineRate5Icon,
    [ThirdPartyConnectModalScenario.WISHLIST]: WineWishlistIcon,
  },
};

const ThirdPartyConnectModal = ({
  retailerLogo,
  isOpen,
  hideModal,
  handleBtnClick,
  producerName,
  scenario,
  productCategory,
  productName,
}: IThirdPartyConnectModalProps) => {
  const { primaryBtnText, secondaryBtnText, infoText, connectText, productSaveTitle, rateProductTitle, locationTitle } =
    localeCommon.thirdPartyConnect;

  const titleText = {
    [ThirdPartyConnectModalScenario.LOCATION]: locationTitle,
    [ThirdPartyConnectModalScenario.WISHLIST]: productSaveTitle,
    [ThirdPartyConnectModalScenario.RATE4]: rateProductTitle,
    [ThirdPartyConnectModalScenario.RATE5]: rateProductTitle,
  };

  return (
    <ModalWrapper maxContentHeight hideModal={hideModal} isOpen={isOpen}>
      <S.ThirdPartyConnectModalWrapper
        isFullWidth
        align="center"
        data-testid="ThirdPartyConnectModal"
        direction="column"
        gap={0}
      >
        <S.Icon align="center" justify="flex-end">
          <CloseButton handleClick={hideModal} />
        </S.Icon>
        <Flexbox isFullWidth align="center" direction="column" gap={20}>
          <Image alt="icon" height="89px" objectFit="contain" src={Icons[productCategory][scenario]} width="83px" />
          <Flexbox align="center" direction="column" gap={8}>
            <Image alt="retailer" height="100px" objectFit="contain" src={retailerLogo} width="250px" />
            <S.CenteredText
              fontFamily="Fraunces"
              localeIndex={{ producerName, productName }}
              size="h6"
              text={titleText[scenario]}
              weight="semibold"
            />
          </Flexbox>
          <Text localeIndex={{ producerName }} size="body1" text={connectText} />
          <Flexbox isFullWidth direction="column" gap={8}>
            <Button
              isFullWidth
              fontSize="subtitle2"
              handleClick={() => handleBtnClick(true)}
              size="md"
              text={primaryBtnText}
            />
            <Button
              isFullWidth
              handleClick={() => handleBtnClick(false)}
              size="md"
              text={secondaryBtnText}
              variant="link"
            />
          </Flexbox>
          <Text localeIndex={{ producerName }} size="body3" text={infoText} />
        </Flexbox>
      </S.ThirdPartyConnectModalWrapper>
    </ModalWrapper>
  );
};

export default ThirdPartyConnectModal;
