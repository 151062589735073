import { Dispatch, FC, SetStateAction, useState } from 'react';
import QRCode from 'react-qr-code';

import ProductDetailsPdfContainer from '@app/web/src/containers/ProductDetailsPdfContainer';

import { IComment, ICommentHandleSubmit } from '@lib/core/comments/types';
import { TProductInstance } from '@lib/core/products/types';
import { parseFindProduct, parseProductInstance } from '@lib/core/products/utils';
import { promotionsFilter } from '@lib/core/service/utils';
import { TFeedback } from '@lib/core/users/slices/feedbacks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_POSITION_CONTEXT, MixpanelPositionContext } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  PROMOTION_BADGE_FIDELITY,
  PROMOTION_BADGE_OTHER,
  PROMOTION_BADGE_PROMOTION,
  VH_VARIANTS,
} from '@lib/tools/shared/helpers/consts';
import { parseTasteMatchLevel } from '@lib/tools/tasteMatch';
import { useAddons } from '@lib/tools/views/hooks';
import { useTheme } from '@lib/tools/views/hooks/useTheme';

import Button from '@components/web/src/atoms/Buttons/Button';
import Comment from '@components/web/src/atoms/Comment/Comment';
import Feedback from '@components/web/src/atoms/Feedback/Feedback/Feedback';
import PriceRange from '@components/web/src/atoms/PriceRange/PriceRange';
import TasteMatchButton from '@components/web/src/atoms/TasteMatchButton/TasteMatchButton';
import TooltipComponent from '@components/web/src/atoms/Tooltips/Tooltip';
import Wishlist from '@components/web/src/atoms/Wishlist/Wishlist';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Cards/ProductDetails/styles';
import ReadMoreCard from '@components/web/src/organisms/Cards/ReadMoreCard/ReadMoreCard';
import CommentForProductCard from '@components/web/src/organisms/Comment/CommentForProductPage';
import { PromotionBadges } from '@components/web/src/organisms/PromotionBadges/PromotionBadges';
import FindProductPopup from '@components/web/src/shared/FindProductPopup/FindProductPopup';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

interface IProps {
  locale: string;
  productInstanceData: TProductInstance;
  mobileKioskQRUrl?: string;
  isShowPromoLabel?: boolean;
  shouldDisplayContactProducerBtn?: boolean;
  isEnableLocationMapAddon?: boolean;
  isShowProductLocationAddon?: boolean;
  isEnableVusionAddon?: boolean;
  shouldHideWishlist?: boolean;
  isProductInstanceInWishlist?: boolean;
  isWishlistProductListLoading?: boolean;
  shouldHideComment?: boolean;
  shouldHideFeedback?: boolean;
  feedback?: TFeedback;
  discoveryQuiz?: IDiscoveryQuiz;
  isUserHasAnyCharacter: boolean;
  isProductPDFDownload?: boolean;
  mixpanelPositionContext?: MixpanelPositionContext;
  navigateToQuizSelectionPage: () => void;
  handleUpdateFeedback?: (feedback: TFeedback, productId: string, productName?: string) => void;
  handleUpdateWishlistProductList?: (productId: string, productName?: string) => void;
  handleAuthFeatureClick?: () => void;
  isCommentsLoaded?: boolean;
  commentsList?: IComment[];
  isNewCommentsDataFetching?: boolean;
  setIsNewCommentsDataFetching?: Dispatch<SetStateAction<boolean>>;
  handleSubmitComment?: ({ isCommentInList, commentFromList, productId, comment }: ICommentHandleSubmit) => void;
}

const ProductDetails: FC<IProps> = ({
  locale,
  productInstanceData,
  isShowPromoLabel = false,
  mobileKioskQRUrl,
  isEnableLocationMapAddon = false,
  isShowProductLocationAddon = false,
  isEnableVusionAddon = false,
  shouldDisplayContactProducerBtn = false,
  shouldHideWishlist = false,
  isWishlistProductListLoading = false,
  isProductInstanceInWishlist = false,
  shouldHideComment = false,
  shouldHideFeedback = false,
  isProductPDFDownload = false,
  isUserHasAnyCharacter,
  feedback,
  mixpanelPositionContext = MP_POSITION_CONTEXT.PRODUCT_PAGE,
  discoveryQuiz,
  handleUpdateFeedback,
  handleUpdateWishlistProductList,
  navigateToQuizSelectionPage,
  handleAuthFeatureClick,
  isCommentsLoaded,
  handleSubmitComment,
  setIsNewCommentsDataFetching,
  isNewCommentsDataFetching,
  commentsList,
}) => {
  const { productDetails: productDetailsMessages, commonMessages } = localeCommon;

  const { isFindProductButtonEnable, productLocationDescription, productLocationMap, productEcommerceId } =
    parseFindProduct({
      isEnableLocationMapAddon,
      isEnableVusionAddon,
      isShowProductLocationAddon,
      productInstanceData,
    });

  const {
    productId,
    productCategory,
    productCharacterName,
    productCharacterTechnicalCaption,
    productName,
    productImage,
    productStyle,
    productDescription,
    productTags,
    productFormat,
    productFormatSize,
    productFormatName,
    productCurrencySymbol,
    productProducerName,
    productProducerUrl,
    productOriginalPrice,
    productDiscountPrice,
    showedProductOriginalPrice,
    showedProductDiscountPrice,
    productRegionName,
    promotions,
    productRegionCountry,
    productTasteMatchValue,
  } = parseProductInstance({
    locale,
    productInstanceData,
  });
  const { isPriceRangeAddon } = useAddons();
  const { primary } = useTheme();
  const [isTooltipVisible, setTooltipVisible] = useState(true);
  const isProductRegionAvailable = !!productRegionCountry && !!productRegionName;

  const promotionBadges = promotionsFilter(promotions, [
    PROMOTION_BADGE_FIDELITY,
    PROMOTION_BADGE_PROMOTION,
    PROMOTION_BADGE_OTHER,
  ]);

  return (
    <S.ProductDetailsContainer data-testid="ProductDetails">
      <S.ProductImageContainer data-testid="ProductImageContainer">
        {isShowPromoLabel && (
          <S.TopPromoIcon>
            <S.PromoText size="body3" text={commonMessages.promo} weight="semibold" />
          </S.TopPromoIcon>
        )}
        <S.ProductImageWrapper>
          <Image alt="product" height="85%" objectFit="contain" src={productImage} />
        </S.ProductImageWrapper>
        <S.TagsWrapper>
          {productTags?.map(tag => <Image key={tag.tagName} alt={tag.tagName} src={tag.icon} />)}
        </S.TagsWrapper>
        <S.ImageCtaWrapper>
          <S.FeedbackWrapper direction="column" gap={16}>
            {!shouldHideWishlist && (
              <Wishlist
                handleAuthFeatureClick={handleAuthFeatureClick}
                isItemInWishlist={isProductInstanceInWishlist}
                isItemListLoading={isWishlistProductListLoading}
                handleUpdateWishlist={() => {
                  handleUpdateWishlistProductList(productId, productName);
                  MixpanelTracker.events.productBookmark(
                    productInstanceData,
                    isProductInstanceInWishlist,
                    null,
                    mixpanelPositionContext,
                  );
                }}
              />
            )}
            {!shouldHideFeedback && (
              <Feedback
                discoveryQuiz={discoveryQuiz}
                feedback={feedback}
                handleAuthFeatureClick={handleAuthFeatureClick}
                handleUpdateFeedback={handleUpdateFeedback}
                productInstanceData={productInstanceData}
              />
            )}
            {!shouldHideComment && (
              <Comment
                commentsList={commentsList}
                handleAuthFeatureClick={handleAuthFeatureClick}
                handleSubmitComment={handleSubmitComment}
                isCommentsLoaded={isCommentsLoaded}
                isNewCommentsDataFetching={isNewCommentsDataFetching}
                productId={productId}
                setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
              />
            )}
          </S.FeedbackWrapper>
          {isProductPDFDownload && !!productInstanceData && (
            <ProductDetailsPdfContainer product={productInstanceData} />
          )}
        </S.ImageCtaWrapper>
      </S.ProductImageContainer>
      <S.ProductContentContainer data-testid="ProductContentContainer">
        {productProducerName && (
          <Text color={STATIC_COLORS.base.black} size="body1" text={productProducerName} weight="semibold" />
        )}

        <Text
          color={STATIC_COLORS.base.black}
          fontFamily="Fraunces"
          linesLimit={2}
          size="h4"
          text={productName}
          weight="semibold"
        />

        <Text
          color={STATIC_COLORS.base.black}
          size="body1"
          text={productStyle || (isProductRegionAvailable && `${productRegionName}, ${productRegionCountry}`) || ''}
        />

        <S.CharacterNameText
          size="body2"
          text={`#${productCharacterName} • ${productCharacterTechnicalCaption}`}
          weight="medium"
        />

        <TooltipComponent
          isAlwaysOpen={isTooltipVisible}
          placement="top-end"
          title={productDetailsMessages.tasteMatchTooltip}
          trigger={
            <TasteMatchButton
              hideTooltip={() => setTooltipVisible(false)}
              navigateToQuizSelectionPage={navigateToQuizSelectionPage}
              productInstanceData={productInstanceData}
              tasteMatchLevel={parseTasteMatchLevel(productTasteMatchValue, isUserHasAnyCharacter)}
              tasteMatchValue={productTasteMatchValue}
            />
          }
        />

        {productDescription && (
          <S.DescriptionWrapper>
            <ReadMoreCard
              isTransparentVariant
              cardTitle={productDetailsMessages.descriptionTitle}
              cardTitleColor={STATIC_COLORS.green['600']}
              description={productDescription}
              minimumCharacters={190}
              handleClick={() =>
                MixpanelTracker.events.productStoryClick(
                  productInstanceData,
                  isProductInstanceInWishlist,
                  mixpanelPositionContext,
                )
              }
            />
          </S.DescriptionWrapper>
        )}
        {!shouldHideComment && (
          <CommentForProductCard
            commentsList={commentsList}
            handleAuthFeatureClick={handleAuthFeatureClick}
            handleSubmitComment={handleSubmitComment}
            isCommentsLoaded={isCommentsLoaded}
            isNewCommentsDataFetching={isNewCommentsDataFetching}
            productId={productId}
            setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
          />
        )}

        <S.PriceAndFormatWrapper>
          {productFormat && (
            <Text
              color={STATIC_COLORS.base.black}
              size="body2"
              text={`${productFormatName}${productFormatSize && `, ${productFormatSize}`}`}
            />
          )}
          {isPriceRangeAddon ? (
            <PriceRange
              currencySymbolValue={productCurrencySymbol}
              originalPrice={productOriginalPrice}
              productCategory={productCategory}
            />
          ) : (
            <S.PriceWrapper>
              {productDiscountPrice ? (
                <>
                  <S.PriceDashText size="subtitle1" text={showedProductOriginalPrice} />
                  <S.PriceText size="subtitle1" text={showedProductDiscountPrice} weight="semibold" />
                </>
              ) : (
                <S.PriceText size="subtitle1" text={showedProductOriginalPrice} weight="semibold" />
              )}
            </S.PriceWrapper>
          )}
        </S.PriceAndFormatWrapper>
        {!!promotionBadges?.length && <PromotionBadges isLargeVariant promotionsData={promotionBadges} />}

        <S.CtaWrapper>
          {mobileKioskQRUrl && (
            <S.QrContainer>
              <QRCode className="qr-code" fgColor={primary['-600']} size={100} value={mobileKioskQRUrl} />
              <S.QrText
                color="var(--color-primary-600)"
                size="body2"
                text={productDetailsMessages.seeItOnYourMobile}
                weight="semibold"
              />
            </S.QrContainer>
          )}
          {isFindProductButtonEnable && !shouldDisplayContactProducerBtn && (
            <FindProductPopup
              isEnableLocationMapAddon={isEnableLocationMapAddon}
              isEnableVusionAddon={isEnableVusionAddon}
              productEcommerceId={productEcommerceId}
              productLocationDescription={productLocationDescription}
              productLocationMap={productLocationMap}
            >
              <Button
                data-testid="FindProductButton"
                size="lg"
                text={productDetailsMessages.findItOnShelf}
                type="button"
                variant={VH_VARIANTS.PRIMARY}
                onClick={() =>
                  MixpanelTracker.events.findMe(
                    productInstanceData,
                    isProductInstanceInWishlist,
                    null,
                    mixpanelPositionContext,
                  )
                }
              />
            </FindProductPopup>
          )}
          {productProducerUrl && (
            <a {...(productProducerUrl ? { href: productProducerUrl, target: '_blank' } : {})}>
              <Button
                data-testid="ContactProducerButton"
                disabled={!productProducerUrl}
                handleClick={() => MixpanelTracker.events.contactTheProductProducer(productInstanceData)}
                size="lg"
                text={productDetailsMessages.contactProducerBtn}
                variant={VH_VARIANTS.PRIMARY}
              />
            </a>
          )}
        </S.CtaWrapper>
      </S.ProductContentContainer>
    </S.ProductDetailsContainer>
  );
};

export default ProductDetails;
